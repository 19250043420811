<template>
  <div @click="$emit('change', variant)" class="color-selection" :class="{'active': isActive}">
    <span class="color-preview" :class="{'border-grey': isWhite}" :style="bgColor">
      <check-icon class="checkmark" :class="{'hidden': !isActive}" />
    </span>
    <span>{{ variant.value }}</span>
  </div>
</template>

<script>
import filterMixin from './filterMixin.ts'
import { CheckIcon } from '@heroicons/vue/outline'

export default {
  mixins: [filterMixin],
  components: {
    CheckIcon
  },
  data() {
    return {
      colorMappings: {
        "Valkoinen": "#fafafa",
        "Luonnonvalkoinen": "#fafae8",
        "Shampanja": "#f7e7ce",
        "Beige": "#dac6a1",
        "Vaaleabeige": "#ede2cc",
        "Tummabeige": "#bfad8c",
        "Harmaa": "#7e7e7e",
        "Vaaleaharmaa": "#c1c1c1",
        "Tummaharmaa": "#464646",
        "Keltainen": "#fcde1c",
        "Vaaleakeltainen": "#fcf4a3",
        "Tummakeltainen": "#edc215",
        "Lila": "#b65fcf",
        "Vaalealila": "#e1bee7",
        "Tummalila": "#710193",
        "Luumu": "#85254a",
        "Musta": "#000000",
        "Oranssi": "#f7890a",
        "Vaaleaoranssi": "#faa639",
        "Tummaoranssi": "#d47406",
        "Persikka": "#ffd2b4",
        "Punainen": "#cc0000",
        "Vaaleanpunainen": "#ffb6d3",
        "Tummanpunainen": "#8a0f0f",
        "Viininpunainen": "#5e031a",
        "Aniliininpunainen": "#d90f94",
        "Roosa": "#ffb6c1",
        "Ruskea": "#634829",
        "Vaaleanruskea": "#947047",
        "Tummanruskea": "#432711",
        "Terra": "#b84221",
        "Sininen": "#3944bc",
        "Vaaleansininen": "#9ac3ed",
        "Tummansininen": "#241571",
        "Turkoosi": "#23bac8",
        "Vaaleaturkoosi": "#57eaf7",
        "Tummanturkoosi": "#078591",
        "Läpinäkyvä": "#fafafa",
        "Alumiini": "#d0d2d9",
        "Kulta": "#d4af37/#fff",
        "Hopea": "#c0c0c0/#fff",
        "Kupari": "#b87333/#fff",
        "Teräs": "#7A7F80/#fff",
        "Messinki": "#b5a642/#fff",
        "Antiikkimessinki": "#8a7f38/#fff",
        "Kromi": "#d3d4d5/#fff",
        "Mattakromi": "#acafb1",
        "Graniitti": "#4c4c45",
        "Helmiäinen": "#f6f4f5",
        "Pronssi": "#ba9d68/#fff",
        "Pellava": "#e8e3d5",
        "Galvanoitu": "#9ba4a8/#fff",
        "Tina": "#9fa19b/#fff",
        "Vihreä": "#4c9d2a",
        "Vaaleanvihreä": "#acdf87",
        "Tummanvihreä": "#1e4519"
      },
    }
  },
  computed: {
    bgColor () {
      let label = this.variant.value;
      if (!label) return ''
      if (this.colorMappings) {
        if (typeof this.colorMappings[label] !== 'undefined') {
          label = this.colorMappings[label]
        }
      }
      if (label.indexOf('/') >= 0) { // to be honest - this is a hack for colors like "ink/white"
        const gradientArray = label.split('/')
        const gradient = gradientArray.map((gradientColor, index) => {
          return `${gradientColor} ${100 / (gradientArray.length - 1) * index}%`
        }).join(',')
        return `background: linear-gradient(90deg, ${gradient}); color: ${label}; border: none;`
      } else {
        return `background-color: ${label}; color: ${label};`
      }
    },
    isWhite () {
      return this.variant.value ? ['Valkoinen', 'Luonnonvalkoinen'].includes(this.variant.value) : false;
    }
  }
}
</script>

<style lang="scss" scoped>
.color-selection {
  @apply mb-2 flex align-top cursor-pointer;
  line-height: 1.5rem;

  .color-preview {
    @apply relative w-24 h-24 rounded-full mr-2 border border-transparent;

      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 9999px;
      margin-right: 0.5rem;
      border-width: 1px;
      border-color: transparent;

    .checkmark {
      top: -1px;
      left: -1px;
      color: currentColor;
      filter: invert(1) saturate(0) brightness(2) contrast(100);
      @apply absolute p-1;
    }
  }
}
</style>
