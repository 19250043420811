<template>
  <!--  <div class="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-3 px-4">-->

  <!--Sidebar with Dimmer -->
  <div class="inset-0 flex transition ease-in-out" :class="[this.searchbar ? 'z-20 fixed' : 'z-0']" >

    <!-- Sidebar -->
    <div
        class="absolute top-0 h-screen z-40 w-full lg:w-search transition duration-500 ease-in-out"
        :class="[this.searchbar ? 'maxmd:block right-0 flex-row -translate-x-0' : 'maxmd:hidden right-0 flex-row-reverse translate-x-full']"
    >
      <!-- Sidebar Content -->
      <div
          ref="content"
          class="bg-white flex items-center justify-center"
          :class="[this.searchbar ? 'max-w-search min-w-search overflow-y-scroll' : 'overflow-hidden']"
          @click.stop="closeFilters"
      >
        <div class="max-h-screen min-h-screen w-full ">
          <div class="px-4 py-3 bg-searchbar max-w-screen">
            <div class="flex justify-between items-center h-full">
              <div class="title">
                <h4 class="normal-case">
                  {{ $t('Search.search') }}
                </h4>
              </div>
              <button class="cursor-pointer" >
                <XIcon class="h-6 xxs:h-8 lg:h-5 inline" @click="toggleSearchBar" />
              </button>
            </div>
          </div>
          <div class="md:col-12 justify-end text-right w-full">
            <div class="search-input-group w-screen lg:w-full">
              <SearchIcon class="h-6 xxs:h-8 lg:h-4 inline lg:mb-2 search-icon"/>
              <input
                  ref="search"
                  id="search"
                  v-model="searchTerm"
                  name="searchTerm"
                  class="search-panel-input"
                  :placeholder="$t('Search.placeholder')"
                  type="search"
                  @keyup="search(500, false)"
              >
            </div>
          </div>
          <div v-if="this.firstSearchDone && this.results && this.results.productSearch && this.results.productSearch.length > 0" class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 gutter-md mt-5 p-5">
            <filter-dropdown  @click.stop key="color" :selections="this.availableColors.length" close-on-blur :isOpen="this.isOpen" @toggleOpen="toggle">
              <template v-slot:title>
                <div class="flex-1 truncate">
                  {{ $t('Search.color') }}
                </div>
              </template>
              <template v-slot:options>
                <color-selector
                    code="color"
                    v-for="(color, index) in this.availableColors"
                    :key="index"
                    :variant="color"
                    @change="this.changeColor(color.value)"
                />
              </template>
            </filter-dropdown>
          </div>

          <div class="w-screen lg:w-search-scroll">
            <div class="col-span-1 md:col-span-3">
              <div class="product-listing row">
                <div v-if="loaded && this.firstSearchDone && this.results.productSearch.length > 0" class="block my-6 uppercase text-base">{{ visibleResults }} / {{ totalResults }} {{ $t('Search.products') }}</div>
                <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-3" v-if="totalResults && loaded && results.productSearch">
                  <div v-for="i in results.productSearch" :key="i" class="product card">
                    <router-link v-if="i.tuoteid" :to="{ name: 'Product', params: { sku: i.sku, tuoteid: i.tuoteid, source: 'search', category: i.euro_productgroupcode }}" class="product-link block" @click="toggleSearchBar">
                      <div class="product-image pb-2 mb-2 border-dashed border-b border-neutral-700" v-if="i && i.prodImages">
                        <cld-image
                            class="cld-responsive"
                            :public-id="i.prodImages.images[0].public_id"
                            dpr="auto"
                            width="auto"
                            progressive
                            sign-url="true"
                        >
                          <cld-transformation crop="fill" width="150" height="200" />
                        </cld-image>
                      </div>
                      <div v-else class="product-image pb-2 mb-2 border-dashed border-b border-neutral-700">
                        <cld-image
                            class="cld-responsive"
                            :public-id="'product-placeholder'"
                            dpr="auto"
                            width="auto"
                            progressive
                            sign-url="true"
                        >
                          <cld-transformation crop="fill" width="150" height="200" />
                        </cld-image>
                      </div>
                      <span class="block product-name">{{i.name}}</span>
                    </router-link>
                    <Variants v-if="i && i.variants && i.variants.length > 0" :variants="getVariantAsJson(i)" :totalVariants="i.totalVariants" @change-variant="changeVariant($event, i)"/>
                  </div>
                </div>
                <div v-else-if="this.firstSearchDone && this.results.productSearch.length <= 0">
                  {{ $t('Search.no-products') }}
                </div>

                <div v-if="loaded && this.firstSearchDone && this.results.productSearch.length > 0" class="block my-6 uppercase text-base">{{ visibleResults }} / {{ totalResults }} {{ $t('Search.products') }}</div>

                <div
                    v-show=" results && results.productSearch && results.productSearch.length"
                    class="buttons-set text-center py35 mt20 px40"
                >
                  <button
                      v-if="canShowMore"
                      @click="loadMore"
                      class="py-4 px-5 bg-dark mt-10 text-white text-15 hover:bg-dark-hover"
                      type="button"
                  >
                    {{ $t('Search.load-more') }}
                  </button>
                  <button
                      @click="toggleSearchBar"
                      class="outline-none py-3 px-5 outline-none border-none"
                      type="button"
                  >
                    {{ $t('Search.close') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <!-- Dimmer -->
      <div
          v-if="dimmer && this.searchbar"
          @click="toggleSearchBar()"
          class="flex-1 bg-gray-400 bg-opacity-75 active:outline-none z-30"
      />
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
import Variants from "@/components/Variants";
import { SearchIcon, XIcon } from '@heroicons/vue/outline'
import ProductFilters from "@/helpers/filters.json";
import ColorSelector from "@/components/search/ColorSelector";
import FilterDropdown from "@/components/search/FilterDropdown";

export default {
  name: 'Search',
  components: { Variants, SearchIcon, XIcon, ColorSelector, FilterDropdown },
  props: ['searchbar', 'toggleSearchBar'],
  data() {
    return {
      searchTerm: '',
      availableColors: [],
      selectedColors: [],
      searchColors: [],
      filteredProducts: null,
      results: {
        productSearch: [],
        // These are unused but will leave them here for now in case they will be used in the future
        // categorySearch: null,
        // cmsSearch: null,
      },
      filters: {
        color: null,
        selectedColors: []
      },
      loaded: false,
      firstSearchDone: false,
      dimmer: true,
      offset: 0,
      totalResults: 0,
      currentPage: 1,
      isOpen: false,
      originalLimit: 20,
      limit: 20,
      canShowMore: false,
      visibleResults: 0,
    }
  },
  watch: {
    searchTerm(searchTerm) {
      if (searchTerm === '') {
        this.reset()
      }
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen;
    },
    closeFilters () {
      this.isOpen = false;
    },
    changeColor(evt) {
      if (!this.searchColors.includes(evt)) {
        this.searchColors.push(evt)
      } else {
        this.searchColors = this.searchColors.filter((value) => {
          return value !== evt
        })
      }
      // Start again from page 1
      this.offset = 0
      this.search(0)
    },
    nextPage() {
      this.offset += 24
      if (this.currentPage >= 1 && this.currentPage < this.getLastPage()) {
        this.currentPage++
      }
      this.search(0)
    },
    prevPage() {
      this.offset -= 24
      if (this.currentPage >= 1) {
        this.currentPage--
      }
      this.search(0)
    },
    getCurrentPage() {
      this.currentPage = this.offset === 0 ? 1 : this.currentPage
      const curPage = this.currentPage
      return curPage
    },
    getLastPage() {
      return Math.ceil(this.totalResults / 24)
    },
    getVariantAsJson(i) {
      // If product has variants attribute, get the data from that to display them under product card,
      // instead of having every different fabric colour treated as different product
      const current = {sku: i.sku, name: i.name, url: i.url, price: i.price, salePrice: i.salePrice, color: i.color}
      const variants = JSON.parse(i.variants)
      variants.push(current)
      return variants
    },
    changeVariant(evt, i) {
      // When you select variant from the selector in the product card, change name, sku and displayed image accordingly
      i.name = evt.name
      i.prodImages.images[0].public_id = evt.img
      i.sku = evt.sku
    },
    getBatchImages(results) {
      const skuList = results.map(r => r.sku)
      const batchUrl = process.env.VUE_APP_VSF_API_PRODUCT_BATCH
      axios.post(batchUrl, {
        sku: skuList
      })
          .then((response) => {
            const images = response.data.result
            results.map(r => r.prodImages = images.find(i => i.sku === r.sku))
            this.results.productSearch = results
            this.ready = true
            this.loaded = true
          })
    },
    updateResults (productSearch, cmsSearch, categorySearch) {
      if(this.results.productSearch && this.results.productSearch.length > 0) {
        this.results.productSearch.push(productSearch)
      } else {
        this.results.productSearch = productSearch;
      }
      this.results.cmsSearch = cmsSearch;
      this.results.categorySearch = categorySearch;
    },
    reset () {
      this.searchTerm ='';
      this.availableColors = [];
      this.selectedColors = [];
      this.searchColors = [];
      this.filteredProducts = null;
      this.results = {
        productSearch: null
        // These are unused but will leave them here for now in case they will be used in the future
        // categorySearch: null;
        // cmsSearch: null;
      };
      this.filters = {
        color: null,
        selectedColors: []
      };
      this.loaded = false;
      this.firstSearchDone = false;
      this.dimmer = true;
      this.offset = 0;
      this.totalResults = 0;
      this.currentPage = 1;
      this.isOpen = false;
      this.limit = 20;
      this.visibleResults = 0;
    },
    loadMore () {
      if (typeof this.searchTerm !== 'undefined' && this.searchTerm.length > 2) {
        this.limit += this.originalLimit ;
        this.search(0, true)
      }
    },
    search (timeout, isLoadMore) {
      if (this.searchTerm === '') {
        this.reset();
      }
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }
      if (this.searchTerm.length > 2) {
        this.searchTimeOut = setTimeout(async () => {
          const vsfUrl = process.env.VUE_APP_KLEVU_URL
          const data = {
            "recordQueries": [
              {
                "id": "ProductSearch",
                "typeOfRequest": "SEARCH",
                "settings": {
                  "query": {
                    "term": this.searchTerm
                  },
                  "limit": this.limit,
                  "offset": 0,
                  "sort": "RELEVANCE",
                  "typeOfRecords": [
                    "KLEVU_PRODUCT"
                  ],
                  "personalisation": {
                    "enablePersonalisation": true,
                    "fields": []
                  },
                },
                "filters": {
                  "applyFilters": {
                    "filters": [
                      {
                        "key": "euro_productgroupcode",
                        "values": ProductFilters.allGroups,
                        "settings": {
                          "singleSelect": "false"
                        },
                      },
                      {
                        key: "euro_brandi",
                        values: ['EKPRO', 'EXK', 'Marimekko', 'Kiskotalo'],
                        settings: {singleSelect: false}
                      },
                      {
                        key: "color",
                        values: this.searchColors,
                        settings: {singleSelect: false}
                      },
                    ],
                  },
                  "filtersToReturn": {
                    "enabled": true,
                    "include": [
                      "color",
                    ],
                    "options": {
                      "order": "INDEX",
                      "limit": 100,
                      "minCount": 1
                    },
                  }
                }
              },
              // These are currently not needed, might change later.
              // {
              //   "id": "CategorySearch",
              //   "typeOfRequest": "SEARCH",
              //   "settings": {
              //     "query": {
              //       "term": this.searchTerm
              //     },
              //     "limit": 5,
              //     "typeOfRecords": [
              //       "KLEVU_CATEGORY"
              //     ]
              //   }
              // },
              // {
              //   "id": "CmsSearch",
              //   "typeOfRequest": "SEARCH",
              //   "settings": {
              //     "query": {
              //       "term": this.searchTerm
              //     },
              //     "limit": 5,
              //     "typeOfRecords": [
              //       "KLEVU_CMS"
              //     ]
              //   }
              // }
            ],
            "context": {
              "apiKeys": [
                  process.env.VUE_APP_KLEVU_API_KEY
              ]
            }
          };

          axios.post(vsfUrl, data)
              .then((response) => {
                this.canShowMore = true;
                if (!isLoadMore) {
                  this.loaded = false;
                  this.limit = 20;
                }
                this.totalResults = response.data.queryResults?.[0].meta.totalResultsFound
                let pages = Math.floor(this.totalResults / this.limit);
                if (pages <= 0) {
                  this.canShowMore = false
                }
                this.visibleResults = this.limit > this.totalResults ? this.totalResults : this.limit;
                return response.data.queryResults;
              })
              .then((data) => {
                this.firstSearchDone = true;
                const productSearch = data.find(type => type.id === 'ProductSearch').records.filter(product => product.euro_productgroupcode && ProductFilters.allGroups.includes(product.euro_productgroupcode));
                this.getBatchImages(productSearch);
                // const cmsSearch = data.find(type => type.id === 'CmsSearch').records;
                // const categorySearch = data.find(type => type.id === 'CategorySearch').records;
                if (productSearch.length > 0) {
                  const availableColors = (data.find(type => type.id === 'ProductSearch').filters).find(type => type.key === 'color').options;
                  this.availableColors = availableColors;
                  this.selectedColors = availableColors;
                }
                this.updateResults(productSearch, null, null)
              })
              .catch((err) => {
                console.log(err)
                // Do not leave this route to browser history
                this.$router.removeRoute()
                this.$router.replace('/404')
              })

        }, timeout);
      }
    }
  },
}
</script>

<style scoped>
.product-listing {
  padding: 20px;
}
.product .product-image {
  height: unset;
}
.product.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
}
.search-input-group {
  display: flex;
  border-bottom: 1px solid #bdbdbd;
  /*width: 100%;*/
  height: 60px;
}
.search-panel-input {
  width: 100%;
  padding: 0 20px;
}
.search-icon {
  width: 24px;
  height: 24px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.6;
  margin: 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
