<template>
  <Header :menu="menu" :searchbar="searchbar" :parent-method="toggleSearchBar" />
  <BreadCrumbs :formattedLocation="formattedLocation" :current="current"/>
  <div id="main-content">
    <div class="content-wrapper">
      <!-- Vue Router does not notice any change if the same component is being addressed, full path key will trigger the change -->
      <router-view :key="$route.fullPath" />
    </div>
  </div>
  <Newsletter />
  <search :searchbar="searchbar" :toggleSearchBar="toggleSearchBar"/>
  <Footer />
</template>

<script>
import Header from './components/Header'
import BreadCrumbs from './components/BreadCrumbs'
import Footer from './components/Footer'
import Search from './components/search/Search'
import Newsletter from './components/Newsletter'
import ProductFilters from "@/helpers/filters.json";

export default {
  name: 'App',
  components: { BreadCrumbs, Header, Footer, Newsletter, Search },
  data() {
    return {
      flatMenu: [],
      formattedLocation: [],
      location: '',
      menu: [
        {
          href: '/',
          title: this.$t('Front page'),
          parent: null
        },
        {
          href: '/kuvasto',
          title: this.$t('Catalog'),
          parent: null,
          children: [
            {
              href: '/kuvasto/verhokankaat',
              title: 'Verhokankaat',
              parent: this.$t('Catalog'),
              children: [
                {
                  href: '/kuvasto/kuviolliset-kankaat',
                  title: 'Kuviolliset kankaat',
                  parent: 'Verhokankaat',
                },
                {
                  href: '/kuvasto/pimennyskankaat',
                  title: 'Pimennyskankaat',
                  parent: 'Verhokankaat',
                },
                {
                  href: '/kuvasto/yksivariset-kankaat',
                  title: 'Yksiväriset kankaat',
                  parent: 'Verhokankaat',
                }
              ]
            },
            {
              href: '/kuvasto/sisustuskankaat',
              title: 'Sisustuskankaat',
              parent: this.$t('Catalog'),
            },
            {
              href: '/kuvasto/kaihtimet-ja-rullaverhot',
              title: 'Kaihtimet ja rullaverhot',
              parent: this.$t('Catalog'),
            },
            {
              href: '/kuvasto/kiskot-ja-tangot',
              title: 'Kiskot ja tangot',
              parent: this.$t('Catalog'),
            },
            {
              href: '/kuvasto/akustiikkatuotteet',
              title: 'Akustiikkatuotteet',
              parent: this.$t('Catalog'),
            },
            {
              href: '/kuvasto/matot-ja-tekstiililaatat',
              title: 'Matot ja tekstiililaatat',
              parent: this.$t('Catalog'),
            },
            {
              href: '/kuvasto/biohajoavat-tekstiilit',
              title: 'Gaia biohajoavat tekstiilit',
              parent: this.$t('Catalog')
            }
          ]
        },
        {
          href: '/',
          title: 'Palvelut',
          parent: null,
          children: [
            {
              href: '/suunnittelu',
              title: 'Suunnittelu',
              parent: 'Palvelut',
            },
            {
              href: '/ompelupalvelu',
              title: 'Ompelu',
              parent: 'Palvelut',
            },
            {
              href: '/asennuspalvelu',
              title: 'Asennus',
              parent: 'Palvelut',
            }
          ]
        },
        {
          href: '/referenssit',
          title: 'Referenssit',
          parent: null,
        },
        {
          href: '/yhteystiedot',
          title: 'Yhteystiedot',
          parent: null,
        },
        {
          href: '/naytekori',
          title: 'Näytekori',
          parent: null,
        }
      ],
      searchbar: false,
    }
  },
  created() {
    this.flatMenu = this.flatten(this.menu);
  },
  computed: {
    current() {
      return this.location && this.location.title || true;
    },
    isProductPage() {
      return this.$route.name === 'Product' ? true : false;
    }
  },
  watch:{
    $route (to, from){
      if (!this.isProductPage) {
        localStorage.setItem('currentPage', to.href)
      }
      if (this.isProductPage) {
        if (to.params.source && to.params.source === 'search') {
          const path = ProductFilters.groups.find(cat => cat.id === to.params.category).page
          localStorage.setItem('currentPage', '/kuvasto/' + path.toLowerCase())
        }
        this.updateBreadCrumb(to.params.source && to.params.source === 'search', to.params.category || null, true);
      } else {
        this.updateBreadCrumb(false, to.params.category, false);
      }
    }
  },
  methods: {
    toggleSearchBar () {
      this.searchbar = !this.searchbar;
      if (this.searchbar) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    },
    flatten (groups) {
      // flatMap all menu items, even the ones inside children and their children and so on
      return (groups || []).flatMap(({children, ...o}) => [o, ...this.flatten(children)]);
    },
    getLocation(loc, index) {
      // Do not get location for front page, we don't want to view that besides with the home icon
      if (loc && loc.href !== '/') {
        index++
        if (loc.parent) {
          this.formattedLocation.push({title: loc.title, href: loc.href, index: index});
          this.getLocation(this.flatMenu.find(i => i.title === loc.parent), index);
        }
        else {
          this.formattedLocation.push({title: loc.title, href: loc.href, index: index});
          // Sort the formattedLocation so that it works properly in both normal pages and product pages
          // Notice - using .reverse() on the breadcrumb code fragment doesn't work, it
          // unreverses when going to product page
          this.formattedLocation.sort(function(a, b) {
            return b.index - a.index;
          })
        }
      }
    },
    updateBreadCrumb(isFromSearch = false, category = null, isProductPage = false) {
      // Separate search results from normal navigation
      // Search result uses euro_productgroup parameter to define category
      // We use that to define path for search results

      // If we refresh a product page
      if (!isFromSearch && !category && isProductPage) {
        const path = localStorage.getItem('currentPage');
        this.location = this.flatMenu.find(i => i.href === path.replaceAll(' ', '-'));
      }
      // If we use product search and click on a product
      else if (isFromSearch && category) {
        const path = ProductFilters.groups.find(cat => cat.id === category).page;
        this.location = this.flatMenu.find(i => i.title === path);
      }
      // "Standard" page loads, aka all pages that are not product page
      else {
        this.location = this.flatMenu.find(i => i.href === this.$route.path);
      }
      // For all pages, update location
      if (this.location) {
        this.formattedLocation = [];
        const index = 0;
        this.getLocation(this.location, index)
      }
    },
  }
}
</script>
