<template>
  <div class=" breadcrumbs  bg-breadcrumb-light flex items-center text-grey text-base md:text-16 py-2 px-5">
    <router-link class="text-breadcrumb " to="/">
      <HomeIcon class="h-5 md:h-6"/>
    </router-link>
        <router-link v-for="loc in formattedLocation" :key="loc.href" :to="loc.href">
          <span class="text-breadcrumb flex justify-center items-center truncate">
            <ChevronRightIcon class="h-2 md:h-3"/> <a :class="[current === loc.title ? 'text-breadcrumb-darker' : 'text-breadcrumb']" class="truncate text-xs md:text-base" >{{loc.title}}</a>
          </span>
        </router-link>
  </div>
</template>

<script>
import {ChevronRightIcon, HomeIcon} from "@heroicons/vue/outline";

export default {
  name: 'BreadCrumbs',
  components: {ChevronRightIcon, HomeIcon},
  props: ['formattedLocation', 'current'],
}
</script>
