<template>
    <div v-if="variants" class="variant-container">
        <div v-for="(variant, index) in variantImages" :key="variant" @click="changeVariant(variant)" class="variant-child">
            <cld-image
            v-if="index <= showMore"
            class="cld-responsive variant-img"
            :class="{ 'variant-active': variant.active }"
            :public-id="variant.img"
            sign-url="true"
            progressive
            width="25"
            dpr="auto"
            @click="toggleActive($event)"
            >
                <cld-transformation :transformation="'ek-product-variant25x25'" />
                <cld-transformation fetch-format="auto" />
            </cld-image>
        </div>
        <button v-if="(showMore + 1) < variantImages.length" @click="loadMoreVariants" class="display-variants-btn">+</button>
        <span v-if="totalVariants && totalVariants > 5">+{{totalVariants - 4}}</span>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Variants',
    emits: ['changeVariant'],
    props: ['variants', 'totalVariants'],
    data() {
        return {
            variantImages: [],
            currentActive: null,
            showMore: 9,
            displayLoadMore: true,
        }
    },
    methods: {
        async fetchVariantImg(variants) {
            const skuList = variants.map(v => v.sku)
            const routeSku = this.$route.params.sku
            const prodImgUrl = process.env.VUE_APP_VSF_API_PRODUCT_BATCH
            axios.post(prodImgUrl, {sku: skuList})
            .then((response) => {
                const images = response.data.result
                images.forEach((img) => {
                    const variant = variants.find(v => v.sku === img.sku)
                    variant.img = img.images[0].public_id
                    this.variantImages.push(variant)
                })
            })
        },
        changeVariant(evt) {
            this.$emit('changeVariant', evt)
        },
        loadMoreVariants() {
            this.showMore += 10
        },
        toggleActive(evt) {
            if (this.currentActive) {
                this.currentActive.target.classList.toggle('variant-active')
            }
            this.currentActive = evt
            evt.target.classList.toggle('variant-active')
        }
    },
    created() {
        this.fetchVariantImg(this.variants)
    }
}
</script>