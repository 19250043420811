<template>
  <div class="newsletter max-w-screen-lg mx-auto mt-12 px-4">
    <span class="h2 heading block text-center">Tilaa uutiskirjeemme</span>
    <!-- Begin Mailchimp Signup Form -->
    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
    <div id="mc_embed_signup" class="bg-neutral-100 rounded-m mt-4 mb-8 p-6">
      <form action="https://eurokangas.us3.list-manage.com/subscribe/post?u=c09df581280b9f43ad3eae4c4&amp;id=f0fa4bb2a3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll" class="text-center">
          <p>Löydä ideoita, inspiraatiota ja ajankohtaista tietoa julki- ja toimistotilojen tekstiilisisustamiseen.</p>
          <div class="mc-field-group mt-4 text-center">
            <input type="email" value="" placeholder="Sähköposti" name="EMAIL" class="required email mx-auto" id="mce-EMAIL">
          </div>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div> <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_c09df581280b9f43ad3eae4c4_f0fa4bb2a3" tabindex="-1" value=""></div>
          <div class="clear"><input type="submit" value="Tilaa" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Newsletter',
  mounted() {
    let mcScript = document.createElement('script')
    mcScript.setAttribute('src', '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js')
    // Inject Cognito Forms script to app, cannot use <script> tags in components
    document.head.appendChild(mcScript)
  },
  created() {
    window.fnames = new Array();
    window.ftypes = new Array();
    fnames[0]='EMAIL';
    ftypes[0]='email';
    fnames[1]='FNAME';
    ftypes[1]='text';
    fnames[2]='LNAME';
    ftypes[2]='text';
    fnames[3]='MMERGE3';
    ftypes[3]='text';
    fnames[4]='MMERGE4';
    ftypes[4]='text';
    fnames[6]='MMERGE6';
    ftypes[6]='text';
  }
}
</script>
