import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import moment from 'moment'
import './assets/scss/custom.scss'
import './assets/index.css'
import Spinner from '@/components/Spinner'
import 'mmenu-js/dist/mmenu.css'
import 'mmenu-js/dist/mmenu.js'
import VueSplide from '@splidejs/vue-splide'
import VueMarkdown from 'vue-markdown-render'
import { createGtm } from '@gtm-support/vue-gtm'
import Cloudinary, { CldImage,CldTransformation }  from 'cloudinary-vue'
import Cart from './store/modules/Cart.js'

const store = createStore({ modules: { Cart } })

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

moment.locale('fi')

// Application setup, add global modules with .add()
let vueApp = createApp(App)
    .component('spinner', Spinner)
    .component('vue-markdown', VueMarkdown)
    .use(router)
    .use(store)
    .use(i18n)
    .use(VueSplide)
    .use(Cloudinary, {
      configuration: {
        basePath: 'media/catalog/product',
        cloudName: 'eurokangas-fi',
        privateCdn: true,
        secure: true,
        secureDistribution: 'images.eurokangas.fi',
        transformations: {
          main: 'b2b-product-page'
        }
      },
      components: [ CldImage, CldTransformation ]
    })
if (process.env.VUE_APP_ENVIRONMENT !== 'local') {
  vueApp.use(createGtm({
    id: 'GTM-M8L9W9D',
    vueRouter: router
  }))
}
vueApp.mount('#app')
