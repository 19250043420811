export default {
  props: {
    variant: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isActive () {
      return this.variant.selected
    }
  }
}
