<template>
  <div class="filter-dropdown w-full relative outline-none" ref="filterdropdown">
    <div class="dropdown-toggle relative" @click="toggle" :class="{ 'open': isOpen, 'selections-made': selections }" >
      <slot name="title" />
      <component :is="arrow" class="flex-none inline ml-2 h-6 xxs:h-8 lg:h-4 inline" />
    </div>
    <div class="dropdown-content" :class="{ 'hidden': !isOpen, 'right-0': onRightEdge }">
      <slot name="options" />
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/outline'

export default {
  components: {
    ChevronDownIcon,
    ChevronUpIcon
  },
  mounted () {
    this.element = this.$refs.filterdropdown;
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnBlur: {
      type: Boolean,
      required: false,
      default: false
    },
    selections: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      element: null,
      onRightEdge: false
    }
  },
  computed: {
    arrow () {
      return this.isOpen ? 'chevron-up-icon' : 'chevron-down-icon';
    }
  },
  methods: {
    toggle () {
      this.checkIfOnRightEdge();
      this.$emit('toggleOpen')
    },
    checkIfOnRightEdge () {
      this.onRightEdge = this.element ? window.innerWidth - this.element.getBoundingClientRect().left < 300 : false;
    }
  },
}
</script>

<style scoped>
  .filter-dropdown {
    max-width: 90vw;
  }
  .dropdown-toggle {
    min-width: 160px;
    height: 48px;
    border-width: 1px;
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
    border-radius: 4px;
    position: relative;
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
  }
  .dropdown-content {
    top: 48px;
    min-width: 280px;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
    margin-top: 0.25rem;
    padding: 1rem;
    max-width: 100%;
    border-width: 1px;
    --border-opacity: 1;
    border-color: #005d86;
    border-color: rgba(0, 93, 134, var(--border-opacity));
    -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 11%);
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 11%);
    border-radius: 4px;
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

</style>
