import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

export const routes = [
  {
    path: '/',
    name: 'Frontpage',
    meta: {
      title: 'Etusivu - Eurokangas Yritysmyynti',
      gtm: 'Etusivu'
    },
    component: () => import(/* webpackChunckName: "Frontpage" */ '../views/Frontpage.vue')
  },
  {
    path: '/referenssit',
    name: 'References',
    meta: {
      title: 'Referenssit - Eurokangas Yritysmyynti',
      gtm: 'Referenssit'
    },
    component: () => import('../views/References.vue')
  },
  {
    path: '/naytekori',
    name: 'Näytekori',
    meta: {
      title: 'Näytekori - Eurokangas Yritysmyynti',
      gtm: 'Näytekori'
    },
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/kuvasto/:slug?',
    name: 'Category',
    meta: {
      title: "Kuvasto - Eurokangas Yritysmyynti",
      gtm: 'Kategoria',
      sitemap: {
        slugs: [
          'kiskot-ja-tangot'
        ]
      }
    },
    component: () => import('../views/Category')
  },
  {
    path: '/referenssit/:slug',
    name: 'Reference',
    meta: {
      title: "Referenssi - Eurokangas Yritysmyynti",
      gtm: "Referenssi",
      sitemap: {
        slugs: [
          'scandic-hamburger-bors'
        ]
      }
    },
    component: () => import('../views/Reference')
  },
  {
    path: '/tuotteet/:tuoteid/:sku',
    name: 'Product',
    meta: {
      title: "Tuote - Eurokangas Yritysmyynti",
      gtm: "Tuote",
      sitemap: {
        slugs: [
          {
            tuoteid: "325200C",
            sku: "325200CB101"
          }
        ]
      }
    },
    component: () => import('../views/Product')
  },
  {
    path: '/artikkelit/:slug',
    name: 'Article',
    meta: {
      title: "Artikkeli - Eurokangas Yritysmyynti",
      gtm: "Artikkeli",
      sitemap: {
        slugs: ['marimekon-paloturvalliset-kankaat-vain-eurokankaalta']
      }
    },
    component: () => import('../views/Article')
  },
  {
    path: '/:slug',
    name: 'Page',
    meta: {
      title: "Sivu - Eurokangas Yritysmyynti",
      gtm: "Sivu",
      sitemap: {
        slugs: ['yhteystiedot']
      }
    },
    component: () => import('../views/Page')
  }
]

const history = typeof window !== 'undefined' ? createWebHistory() : createMemoryHistory()

const router = createRouter({
  history: history,
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  // Use either product SKU or slug in URL for page title
  let title = to.params.sku
  if (!title) {
    title = to.params.slug ? to.params.slug.charAt(0).toUpperCase() + to.params.slug.slice(1).replaceAll('-', ' ') : null
  }
  if (!title) {
    document.title = `${to.meta.title}`
  } else {
    // Hack one known menu item with ä letter
    title = title === 'Yksivariset kankaat' ? 'Yksiväriset kankaat' : title
    document.title = `${title} - Eurokangas Yritysmyynti`
  }
  next()
})

export default router
