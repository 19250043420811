<template>
  <div id="footer">
    <div class="first">
      <div class="content-wrapper">
        <div class="usp-container">
          <span class="usp">{{ $t('Regional contact person') }}</span>
          <span class="usp">{{ $t('Extensive collection of fireproof fabrics') }}</span>
          <span class="usp">{{ $t('Manufacture of rails and blinds in own production in Finland') }}</span>
          <span class="usp">{{ $t('Services from design, implementation and installation') }}</span>
        </div>
      </div>
    </div>
    <div class="second">
      <div class="content-wrapper max-w-screen-lg">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-10">
          <div v-for="(element, index) in footerLinks"
               :key="element.title">
            <p class="heading">{{ element.title }}</p>
            <router-link v-for="link in element.links"
                         :key="link.href"
                         :to="link.href">
              {{ link.title }}
            </router-link>
            <div v-if="index === footerLinks.length - 1" class="mt-6">
              <a href="http://www.instagram.com/eurokangas_pro" target="_new">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="inline" style="fill: white" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                <span class="inline text-sm ml-2">@eurokangas_pro</span>
              </a>
            </div>
          </div>
        </div>
        <div class="content-wrapper trusted-partner">
          <img src="/images/luotettava_kumppani.jpg" class="inline"/>
          <img src="/images/suomalaistapalvelua_suomi_nega_rgb.png" class="inline"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      footerLinks: [
        {
          title: this.$t('Products and catalog'),
          links: [
            {href: '/kuvasto/verhokankaat', title: 'Verhokankaat'},
            {href: '/kuvasto/sisustuskankaat', title: 'Sisustuskankaat'},
            {href: '/kuvasto/kaihtimet-ja-rullaverhot', title: 'Kaihtimet ja rullaverhot'},
            {href: '/kuvasto/kiskot-ja-tangot', title: 'Kiskot ja tangot'},
            {href: '/kuvasto/akustiikkatuotteet', title: 'Akustiikkatuotteet'},
            {href: '/kuvasto/matot-ja-tekstiililaatat', title: 'Matot ja tekstiililaatat'}
          ]
        },
        {
          title: this.$t('Services'),
          links: [
            {href: '/suunnittelu', title: 'Suunnittelu'},
            {href: '/ompelupalvelu', title: 'Ompelu'},
            {href: '/asennuspalvelu', title: 'Asennus'},
          ]
        },
        {
          title: this.$t('Business sales'),
          links: [
            {href: '/referenssit', title: 'Referenssit'},
            {href: '/yhteystiedot', title: 'Yhteystiedot'}
          ]
        }
      ]
    }
  }
}
</script>
