let state = {
    cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {items: []}
} // this is read onload, so if you need data present on load, add it to localStorage

// Key elements of a store module
// Call actions with this.$store.dispatch('Cart/ADD_TO_CART', payload) from components
const actions = {
    INIT_CART({commit}) {
        let cart = {
            items: []
        }
    },
    ADD_TO_CART: ({commit, store}, payload) => {
        commit('ADD_TO_CART', payload)
    },
    REMOVE_FROM_CART: ({commit, store}, payload) => {
        commit('REMOVE_FROM_CART', payload)
    },
    EMPTY_CART: ({commit}) => {
        commit('EMPTY_CART')
    }
}

// Call mutations with this.$store.commit('SomeModule/SOME_MUTATION', optional_data)
// Used to manipulate data
// Called from actions with commit('ADD_TO_CART', payload)
const mutations = {
    ADD_TO_CART(state, product) {
        if (!state.cart.items.find((item) => item.sku === product.sku)) {
            state.cart.items.push(product)
        }
       localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    REMOVE_FROM_CART(state, product) {
        const index = state.cart.items.indexOf(product);
        if (index > -1) { // only splice array when item is found
            state.cart.items.splice(index, 1); // 2nd parameter means remove one item only
            localStorage.setItem('cart', JSON.stringify(state.cart))
        }
    },
    EMPTY_CART(state) {
        let cart = {
            items: []
        }
        state.cart = cart
        localStorage.setItem('cart', JSON.stringify(state.cart))
    },
}

// Call getters with this.$store.getters['Cart/GET_CART'] in components
// Used to return data
// NO manipulation!
const getters = {
    GET_CART: state => state.cart
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
